<template>
  <div
    v-resize="onResize"
    class="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-between"
    :style="offsetHeight"
  >
    <LoadingSpinner v-if="isLoading" />
    <div
      v-else
      class="container-bg tw-z-10 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-py-3"
    >
      <div class="tw-flex tw-items-center">
        <router-link
          class="tw-mr-4 tw-cursor-pointer"
          to="/"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </router-link>
        <div class="tw-line-clamp-2 tw-text-lg tw-font-bold">
          {{ ticket.summary }}
        </div>
      </div>
      <div class="lg:tw-hidden">
        <v-menu location="bottom left">
          <template #activator="{ props: activatorProps }">
            <v-btn
              icon="mdi-dots-vertical"
              v-bind="activatorProps"
            />
          </template>
          <v-list>
            <v-list-item @click="showDetails">
              <v-list-item-title>Ticket Details</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeTicketStatus">
              <v-list-item-title>{{ statusLabel }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div
      ref="ticketHistoryElem"
      :class="`tw-absolute tw-w-full tw-overflow-y-auto tw-py-14`"
      :style="offsetHeight"
    >
      <TicketEntry
        :entry="ticket"
        :theme="portalSettings.theme"
        :portal-user-uid="currentUser.uid"
      />
      <TicketEntry
        v-for="entry in entries"
        :key="entry.id"
        :entry="entry"
        :theme="portalSettings.theme"
        :portal-user-uid="currentUser.uid"
      />
    </div>
    <div class="container-bg tw-z-10 tw-w-full tw-overflow-auto tw-py-3">
      <v-form
        ref="form"
        class="tw-flex tw-flex-row tw-items-end"
        @submit.prevent="onSubmit"
      >
        <v-textarea
          v-model="newEntry"
          :error-messages="newEntryErrors"
          placeholder="Type a response or question"
          bg-color="white"
          auto-grow
          rows="1"
          @keydown="v$.newEntry.$touch"
        ></v-textarea>
        <SpiceworksButton
          button-type="primary"
          icon="mdi-send"
          icon-size="default"
          text="Send"
          :hide-text-on-mobile="true"
          class="tw-mb-1.5 tw-ml-2"
          min-width="4rem"
          min-height="2.5rem"
          type="submit"
          :disabled="v$.newEntry.$invalid"
        />
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf, maxLength } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { useMobile } from '@/src/common/composables/useMobile';

import TicketEntry from '@/src/common/components/TicketEntry.vue';
import LoadingSpinner from '@/src/portal/components/LoadingSpinner.vue';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';
import { createTicketComment, getTicketComments } from '@/src/portal/services/portal-service.js';

const store = useStore();
const newEntry = ref('');
const entries = ref([]);
const historyOffsetTop = ref(64);
const isLoading = ref(false);
const currentUser = computed(() => store.state.currentUser);
const portalSettings = computed(() => store.state.portalSettings);
const ticketHistoryElem = ref(null);
const form = ref(null);

const rules = {
  newEntry: {
    required: requiredIf(() => newEntry.value.trim().length === 0),
    maxLength: maxLength(100000),
  },
};

const v$ = useVuelidate(rules, { newEntry });

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
  onChangeTicketStatus: {
    type: Function,
    required: true,
  },
});

const statusLabel = computed(() => {
  return `${props.ticket.status == 'closed' ? 'Reopen' : 'Close'} Ticket`;
});

const newEntryErrors = computed(() => {
  const errors = [];
  if (!v$.value || !v$.value.newEntry.$dirty) return errors;

  if (v$.value.newEntry.required.$invalid) {
    errors.push("The response can't be empty.");
  } else if (v$.value.newEntry.maxLength.$invalid) {
    errors.push('The response should be less than 100,000 characters.');
  }
  return errors;
});

const offsetHeight = computed(() => {
  return `height: calc(var(--vh, 1vh) * 100 - ${historyOffsetTop.value}px);`;
});

const loadEntries = async () => {
  isLoading.value = true;
  entries.value = await getTicketComments(props.ticket.id);
  isLoading.value = false;
};

onMounted(loadEntries);

const onSubmit = async () => {
  v$.value.newEntry.$touch();
  if (!v$.value.newEntry.$invalid) {
    const response = await createTicketComment(props.ticket.id, {
      ticket_comment: {
        body: newEntry.value,
        creator_id: null,
        ticket_id: props.ticket.id,
      },
    });

    const snackbarProps = {
      display: true,
      type: 'success',
      message: 'Your comment has been created.',
    };

    if (response.errors) {
      snackbarProps.type = 'error';
      snackbarProps.message = response.errors;
    } else {
      form.value.reset();
      v$.value.newEntry.$reset();
      newEntry.value = '';
      await loadEntries();

      // Scroll to bottom to show latest ticket comment
      ticketHistoryElem.value.scrollTop = ticketHistoryElem.value.scrollHeight;
    }

    store.dispatch('showSnackbar', snackbarProps);
  }
};

const changeTicketStatus = async () => {
  try {
    await props.onChangeTicketStatus();
  } catch (err) {
    store.dispatch('showSnackbar', {
      display: true,
      type: 'error',
      message: 'There was an error updating the ticket status. Please try again.',
    });
  }
};

const emit = defineEmits(['update:show-details-modal']);

const showDetails = () => {
  emit('update:show-details-modal', true);
};

const { isMobile } = useMobile();

const onResize = () => {
  if (!isMobile.value) {
    historyOffsetTop.value = ticketHistoryElem.value.getBoundingClientRect().top + 25;
  }
};
</script>

<style scoped>
.container-bg {
  --bg-opacity: 0.95;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}
</style>
