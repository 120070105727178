<template>
  <PortalLayout @set-viewport-height="setViewportHeight">
    <router-view></router-view>
  </PortalLayout>
</template>

<script setup>
import PortalLayout from './views/PortalLayout.vue';
import { onMounted } from 'vue';

// Set viewport height. This is necessary to calculate viewport for mobile browsers with built in footer.
const setViewportHeight = () => {
  const vh = document.documentElement.clientHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

onMounted(setViewportHeight);
</script>
