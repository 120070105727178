<template>
  <div>
    <v-autocomplete
      v-model="customAttributeValue"
      :items="customAttribute.options"
      :label="customAttrLabel(modelValue)"
      :error-messages="errorMessages"
    >
    </v-autocomplete>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const customAttribute = ref({ ...props.modelValue });

const customAttributeValue = computed({
  get: () => customAttribute.value.value,
  set: (val) => {
    customAttribute.value = { ...props.modelValue, value: val };
    emit('update:modelValue', customAttribute.value);
  },
});

const customAttrLabel = (attr) => {
  return attr.required ? `${attr.name} (required)` : attr.name;
};

const rules = {
  value: {
    required: requiredIf((_value, model) => model.required),
  },
};

const v$ = useVuelidate(rules, customAttribute);

const errorMessages = computed(() => {
  if (v$.value.$error) {
    return Object.values(v$.value.$errors).map((error) => error.$message);
  }
  return [];
});
</script>
