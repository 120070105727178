import axios from 'axios';
import { toCamelCase } from '@/src/util/service-mixins.js';

// Global configuration for requests
axios.defaults.headers.common['X-CSRF-TOKEN'] =
  document.querySelector('[name="csrf-token"]').content;

export async function getPortalSettings(portalSettingsId) {
  const requestUrl = `/api/portal/settings/${portalSettingsId}`;

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getEndUsers(endUserId) {
  const requestUrl = `/api/portal/end_users/${endUserId}`;

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getCustomAttributes() {
  const requestUrl = '/api/portal/custom_attributes';

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function saveTicket(ticketData) {
  const requestUrl = '/api/portal/tickets';

  try {
    const response = await axios.post(requestUrl, ticketData);
    return toCamelCase(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getTickets(filter = 'open') {
  const requestUrl = `/api/portal/tickets?view=${filter}`;

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data.tickets);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getTicket(ticketNumber) {
  const requestUrl = `/api/portal/tickets/${ticketNumber}`;

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data.portal_ticket);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function updateTicket(ticketData) {
  const requestUrl = `/api/portal/tickets/${ticketData.ticket.number}`;

  try {
    const response = await axios.put(requestUrl, ticketData);
    return toCamelCase(response.data.portal_ticket);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function getTicketComments(ticketNumber) {
  const requestUrl = `/api/portal/tickets/${ticketNumber}/ticket_comments`;

  try {
    const response = await axios.get(requestUrl);
    return toCamelCase(response.data.ticket_comments);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function createTicketComment(ticketNumber, ticketComment) {
  const requestUrl = `/api/portal/tickets/${ticketNumber}/ticket_comments`;

  try {
    const response = await axios.post(requestUrl, ticketComment);
    return toCamelCase(response.data.comment);
  } catch (error) {
    /* istanbul ignore next */
    if (error.response) {
      return error.response.data;
    }
  }
}
