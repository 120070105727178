import { createRouter, createWebHistory } from 'vue-router';
import store from './vuex/portal-store.js';
import LandingPage from './views/LandingPage.vue';
import CreateTicket from './views/CreateTicket.vue';
import PortalTicket from './views/PortalTicket.vue';
import stats from '@/src/util/stats.js';

export function createNewRouter() {
  const router = createRouter({
    history: createWebHistory('/portal/'),
    routes: [
      {
        path: '/ticket',
        component: CreateTicket,
        name: 'CreateTicket',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/ticket/:ticketId',
        component: PortalTicket,
        name: 'Ticket',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/',
        component: LandingPage,
        name: 'Landing',
      },
      {
        path: '/:catchAll(.*)',
        component: LandingPage,
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    const { currentUser } = store.state.portalSettings;

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      currentUser ? next() : next({ name: 'Landing' });
    } else {
      next();
    }
  });

  router.afterEach((_to, from) => {
    stats.pageView(from);
  });
  return router;
}
