<template>
  <div class="tw-flex tw-h-40 tw-w-full tw-items-center tw-justify-center">
    <v-progress-circular
      :size="70"
      :width="7"
      :color="themeColor"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const portalSettings = computed(() => store.state.portalSettings);

const themeColor = computed(() => getThemeColor(portalSettings.value.theme));

function getThemeColor(theme) {
  const color = {
    blue: '#2099BD',
    red: '#E97472',
    orange: '#FF9E1B',
    grey: '#989A9C',
    green: '#6DA744',
    purple: '#75559F',
    indigo: '#1044AA',
    cyan: '#49A3AA',
  };
  return color[theme];
}
</script>
