import { createApp } from 'vue';
import { createStore } from 'vuex';

import PortalView from '@/src/portal/PortalView.vue';
import vuetify from '@/src/plugins/vuetify.js';
import { createNewRouter } from '@/src/portal/router';
import storeConfig from '@/src/portal/vuex/portal-store.js';

document.addEventListener('DOMContentLoaded', async () => {
  const el = document.getElementById('portal-vue');
  const portalSettingsId = el.getAttribute('portal-settings-id');
  const gRecaptchaSiteKey = el.getAttribute('grecaptcha-site-key');

  const store = createStore(storeConfig);
  await store.dispatch('setPortalData', portalSettingsId);
  await store.dispatch('setGRecaptchaSiteKey', gRecaptchaSiteKey);

  const router = createNewRouter(store);
  createApp(PortalView).use(router).use(store).use(vuetify).mount('#portal-vue');
});
