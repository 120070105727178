<template>
  <article class="tw-w-full tw-rounded tw-border tw-bg-white tw-shadow-portal">
    <div class="tw-p-4 sm:tw-p-6">
      <div class="tw-mb-1 tw-text-xl tw-font-bold">{{ portalSettings.formTitle }}</div>
      <div class="tw-text-sm">
        {{ portalSettings.formMessage }}
        <span v-if="portalSettings.includeEmail">
          You may also send tickets directly to
          <a
            :href="`mailto:${portalSettings.incomingEmail}`"
            class="tw-text-black tw-underline"
            >{{ portalSettings.incomingEmail }}</a
          >
        </span>
      </div>
    </div>
    <hr />
    <v-form
      ref="form"
      class="tw-flex tw-flex-col tw-p-4 sm:tw-p-6"
      @submit.prevent="onSubmit"
    >
      <VueRecaptcha
        ref="invisibleRecaptcha"
        size="invisible"
        :sitekey="gRecaptchaSiteKey"
        @verify="onVerify"
      ></VueRecaptcha>

      <v-text-field
        v-if="!currentUser"
        v-model="email"
        :error-messages="emailErrorMessages"
        label="Contact Email (required)"
        class="pb-6 tw-pb-2"
      ></v-text-field>

      <v-text-field
        v-model="summary"
        :error-messages="summaryErrorMessages"
        :counter="255"
        label="Summary (required)"
        class="tw-pb-2"
        @update:model-value="v$.summary.$touch()"
      ></v-text-field>

      <v-textarea
        v-model="description"
        :error-messages="descriptionErrorMessages"
        :counter="2000"
        label="Description (required)"
        class="tw-pb-2"
        @update:model-value="v$.description.$touch()"
      ></v-textarea>
      <v-autocomplete
        v-if="portalSettings.includeCategory"
        v-model="categoryId"
        :items="ticketCategories"
        item-title="name"
        item-value="id"
        :error-messages="categoryIdErrorMessages"
        :label="categoryLabel"
        class="tw-pb-2"
      ></v-autocomplete>

      <div
        v-for="(v, index) in customAttributes"
        :key="v.customAttributeId"
        class="tw-pb-2"
      >
        <CustomAttributeList
          v-if="v.customAttributeType === 'list'"
          v-model="customAttributes[index]"
        >
        </CustomAttributeList>

        <CustomAttributeTextArea
          v-if="v.customAttributeType === 'text_area'"
          v-model="customAttributes[index]"
        ></CustomAttributeTextArea>

        <CustomAttributeTextField
          v-if="v.customAttributeType === 'text_field'"
          v-model="customAttributes[index]"
        ></CustomAttributeTextField>

        <CustomAttributeTextField
          v-if="v.customAttributeType === 'number'"
          v-model="customAttributes[index]"
          is-number
        ></CustomAttributeTextField>

        <CustomAttributeDatePicker
          v-if="v.customAttributeType === 'date'"
          v-model="customAttributes[index]"
        ></CustomAttributeDatePicker>

        <CustomAttributePhoneNumber
          v-if="v.customAttributeType === 'phone'"
          v-model="customAttributes[index]"
        ></CustomAttributePhoneNumber>
      </div>

      <div
        v-if="allowAttachments"
        class="tw-w-64"
      >
        <!-- Attachments -->
        <input
          v-show="false"
          ref="fileInput"
          type="file"
          @change="uploadAttachment"
        />

        <SpiceworksButton
          icon="mdi-paperclip"
          text="Attach a file"
          class="mb-4 mt-3"
          @click.prevent="$refs.fileInput.click()"
        />

        <div
          v-for="(attachment, index) in attachments"
          :key="attachment.filename"
          class="attachment-card tw-b-0 tw-my-2 tw-block tw-w-80 tw-bg-earl-40 tw-px-3 tw-py-2"
        >
          <v-progress-circular
            v-if="attachment.uploading"
            :size="18"
            :width="2"
            indeterminate
            class="tw-mr-2"
          />
          <v-icon
            v-else
            class="tw-mr-1"
            >mdi-check</v-icon
          >
          {{ truncateFilename(attachment.filename, 28) }}
          <v-btn
            class="remove-attachment tw-float-right tw-p-0"
            :height="20"
            variant="outlined"
            icon="mdi-close"
            @click="removeAttachment(index)"
          />
        </div>
      </div>

      <div class="tw-mt-2 tw-flex tw-flex-row">
        <SpiceworksButton
          button-type="primary"
          text="Submit"
          type="submit"
          :loading="loading"
          :disabled="loading || uploadingAttachment"
        />
      </div>
    </v-form>
  </article>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, email as emailValidator, requiredIf } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';
import { saveTicket } from '@/src/portal/services/portal-service.js';
import { useUploads } from '@/src/common/composables/useUploads.js';
import CustomAttributeTextArea from '@/src/portal/components/custom_attributes/CustomAttributeTextArea.vue';
import CustomAttributeTextField from '@/src/portal/components/custom_attributes/CustomAttributeTextField.vue';
import CustomAttributeList from '@/src/portal/components/custom_attributes/CustomAttributeList.vue';
import CustomAttributePhoneNumber from '@/src/portal/components/custom_attributes/CustomAttributePhoneNumber.vue';
import CustomAttributeDatePicker from '@/src/portal/components/custom_attributes/CustomAttributeDatePicker.vue';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

const store = useStore();

const email = ref('');
const fileInput = ref(null);
const summary = ref('');
const description = ref('');
const categoryId = ref('');
const attachments = ref([]);
const loading = ref(false);

const currentUser = computed(() => store.state.currentUser);

const rules = computed(() => ({
  email: {
    required: requiredIf(() => !currentUser.value),
    emailValidator,
  },
  summary: { required, maxLength: maxLength(255) },
  description: { required, maxLength: maxLength(2000) },
  categoryId: {
    required: requiredIf(() => store.state.portalSettings.requireCategory),
  },
}));

const v$ = useVuelidate(rules, { email, summary, description, categoryId });

const gRecaptchaSiteKey = computed(() => store.state.gRecaptchaSiteKey);

const ticketCategories = computed(() => store.state.ticketCategories);

const portalSettings = computed(() => store.state.portalSettings);

// TODO: improve the authentication logic for Active Directory features
// For now, only logged users are allowed to use attachments
const allowAttachments = computed(() => currentUser.value);

const categoryLabel = computed(() => {
  return portalSettings.value.requireCategory ? 'Category (required)' : 'Category';
});

const uploadingAttachment = computed(() => {
  return attachments.value.some((file) => file.uploading === true);
});

const showSnackbar = store.dispatch.bind(store, 'showSnackbar');

const customAttributes = computed(() => {
  const portalCustomAttributes = store.state.portalCustomAttributes.map((attr) => ({
    value: null,
    ticketId: null,
    customAttributeId: attr.id,
    customAttributeType: attr.attrType,
    required: attr.requiredInPortal,
    options: attr.attrOptions,
    name: attr.name,
  }));

  // TODO: This should be done on the backend.
  // Sort in ascending order by custom attribute id
  return portalCustomAttributes.sort((a, b) =>
    a.customAttributeId > b.customAttributeId ? 1 : -1
  );
});

const invisibleRecaptcha = ref(null);

const emailErrorMessages = computed(() => {
  if (v$.value.email.$error) {
    if (v$.value.email.required.$invalid) {
      return ['Email is required.'];
    } else if (v$.value.email.emailValidator.$invalid) {
      return ['Email must be valid.'];
    }
  }
  return [];
});
const summaryErrorMessages = computed(() => {
  if (v$.value.summary.$dirty) {
    if (v$.value.summary.required.$invalid) {
      return ['Summary is required.'];
    } else if (v$.value.summary.maxLength.$invalid) {
      return ['Summary must be at most 255 characters long.'];
    }
  }
  return [];
});

const descriptionErrorMessages = computed(() => {
  if (v$.value.description.$dirty) {
    if (v$.value.description.required.$invalid) {
      return ['Description is required.'];
    } else if (v$.value.description.maxLength.$invalid) {
      return ['Description must be at most 2000 characters long.'];
    }
  }
  return [];
});

const categoryIdErrorMessages = computed(() => {
  if (v$.value.categoryId.$error) {
    if (v$.value.categoryId.required.$invalid) {
      return ['Category is required.'];
    }
  }
  return [];
});

const form = ref(null);

async function onVerify(gRecaptchaResponse) {
  loading.value = true;

  // Clone custom attribute state and remove required property. Not required by backend
  const customAttrs = customAttributes.value.map((attr) => ({
    value: attr.value,
    ticket_id: attr.ticketId,
    custom_attribute_id: attr.customAttributeId,
    custom_attribute_type: attr.customAttributeType,
  }));

  const payload = {
    ticket: {
      creator: {
        email: email.value,
        avatar_url: '', // TODO: Why do we need this as part of the payload?
      },
      summary: summary.value,
      initial_upload_ids: Array.from(attachments.value, (attachment) => attachment.signedId),
      description: description.value,
      ticket_category_id: categoryId.value,
      custom_values: customAttrs,
    },
    'g-recaptcha-response': gRecaptchaResponse,
  };

  const response = await saveTicket(payload);

  loading.value = false;

  const successMessage = [portalSettings.value.successTitle, portalSettings.value.successMessage]
    .join(' ')
    .trim();

  const snackbarProps = {
    display: true,
    type: 'success',
    message: successMessage || 'Your ticket has been submitted!',
  };
  if (response.errors) {
    snackbarProps.type = 'error';
    snackbarProps.message = response.errors;
  } else {
    // Clear ticket form, attachments and reset vuelidate state
    invisibleRecaptcha.value.reset();
    form.value.reset();
    v$.value.$reset();
    attachments.value = [];
  }

  showSnackbar(snackbarProps);
}

function onSubmit() {
  v$.value.$touch();
  if (!v$.value.$invalid) {
    invisibleRecaptcha.value.execute();
  }
}

const { humanizeByteSize, fileChecksum, addAttachment, uploadError, uploadFile, truncateFilename } =
  useUploads();
async function uploadAttachment(event) {
  const fileSizeLimit = 200000000;
  const file = event.target.files[0];

  const attachment = {
    filename: file.name,
    byteSize: file.size,
    contentType: file.type,
    organization_id: store.state.portalSettings.organizationId,
    uploading: true,
    signedId: null,
  };

  try {
    if (!file) {
      return;
    }

    if (file.size > fileSizeLimit) {
      throw 'File is too big. The size limit is ' + humanizeByteSize(fileSizeLimit) + '.';
    }

    // 1) Display the file in the view
    attachments.value.push(attachment);
    // 2) Update the checksum (this could take time)
    attachments.value[findAttachmentIndex(attachment)].checksum = await fileChecksum(file);

    // 3) Create an attachment record and get the signedID
    const blob = await addAttachment(attachment);

    if (uploadError.value || !blob) {
      throw `Error uploading file ${attachment.filename}`;
    }

    // 4) Upload the file to the server
    await uploadFile(blob, file);

    const index = findAttachmentIndex(attachment);
    if (index !== -1) {
      attachments.value[index] = {
        ...attachments.value[index],
        uploading: false,
        signedId: blob.signedId,
      };
    }
  } catch (error) {
    showSnackbar({ display: true, message: error, type: 'error' });
    removeAttachment(findAttachmentIndex(attachment));
  } finally {
    // Clear the input file
    fileInput.value.value = '';
  }
}

function removeAttachment(i) {
  if (i !== -1) {
    attachments.value.splice(i, 1);
  }
}

function findAttachmentIndex(attachment) {
  return attachments.value.findIndex(
    (a) => a.filename === attachment.filename && a.byteSize === attachment.byteSize
  );
}
</script>

<style>
.remove-attachment {
  border: none !important;
  padding: 0 0.5rem !important;
  width: 1rem !important;
}
</style>
