<template>
  <section class="tw-w-full">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-flex-row sm:tw-items-start"
    >
      <div
        class="tw-mx-4 tw-w-full tw-flex-none tw-border-t tw-bg-white tw-px-4 tw-shadow-portal sm:tw-rounded sm:tw-border sm:tw-p-6 lg:tw-max-w-152 lg:tw-flex-auto"
      >
        <TicketHistory
          v-if="ticket"
          :ticket="ticket"
          :on-change-ticket-status="changeTicketStatus"
          @update:show-details-modal="showDetailsModal = $event"
        />
      </div>
      <div class="tw-mx-4 tw-hidden tw-w-80 tw-flex-none lg:tw-block">
        <TicketDetails
          v-if="ticket"
          :ticket="ticket"
          :on-change-ticket-status="changeTicketStatus"
          :show-details-modal="showDetailsModal"
          @update:show-details-modal="showDetailsModal = $event"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import TicketHistory from '@/src/portal/components/TicketHistory.vue';
import TicketDetails from '@/src/portal/components/TicketDetails.vue';
import { getTicket, updateTicket } from '@/src/portal/services/portal-service.js';

const store = useStore();
const route = useRoute();

const ticket_number = route.params.ticketId;
const ticket = ref(null);

const showDetailsModal = ref(false);

const parseCustomAttributes = computed(() => {
  const custom_attributes = [];
  let custom_value;

  for (let i = 0; i < ticket.value.customValues.length; i++) {
    custom_value = ticket.value.customValues[i];

    custom_attributes.push({
      id: custom_value.id,
      value: custom_value.value,
      ticket_id: custom_value.ticket.id,
      custom_attribute_id: custom_value.customAttribute.id,
      custom_attribute_type: custom_value.customAttribute.type,
    });
  }

  return custom_attributes;
});

onMounted(async () => {
  ticket.value = await getTicket(ticket_number);
});

async function changeTicketStatus(shouldShowSnackbar = true) {
  const new_status = ticket.value.status == 'closed' ? 'open' : 'closed';

  const response = await updateTicket({
    ticket: {
      number: ticket.value.id,
      description: ticket.value.description,
      status: new_status,
      summary: ticket.value.summary,
      custom_values: parseCustomAttributes.value,
    },
  });

  const snackbarProps = {
    display: true,
    type: 'success',
    message: 'Your ticket has been updated.',
  };

  if (response.errors) {
    snackbarProps.type = 'error';
    snackbarProps.message = response.errors;
  } else {
    ticket.value.status = new_status;
  }

  if (shouldShowSnackbar) {
    await store.dispatch('showSnackbar', snackbarProps);
  }
}
</script>
