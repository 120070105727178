<template>
  <PortalWrapper :announcement="portalSettings.pageAnnouncements">
    <TicketList v-if="currentUser" />
    <TicketForm v-else />
  </PortalWrapper>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import TicketForm from '@/src/portal/components/TicketForm.vue';
import TicketList from '@/src/portal/components/TicketList.vue';
import PortalWrapper from '@/src/portal/components/PortalWrapper.vue';

const store = useStore();

const portalSettings = computed(() => store.state.portalSettings);
const currentUser = computed(() => store.state.currentUser);
</script>
