<template>
  <v-text-field
    v-model="customAttributeValue"
    :counter="isNumber ? undefined : 255"
    :label="customAttrLabel(modelValue)"
    :error-messages="errorMessages"
    :type="isNumber ? 'number' : 'text'"
  >
  </v-text-field>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { maxLength, requiredIf } from '@vuelidate/validators';

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true,
  },
  isNumber: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const customAttribute = ref({ ...props.modelValue });

const customAttributeValue = computed({
  get: () => customAttribute.value.value,
  set: (val) => {
    customAttribute.value = { ...props.modelValue, value: val };
    emit('update:modelValue', customAttribute.value);
  },
});

const rules = {
  value: {
    required: requiredIf((_value, model) => model.required),
    maxLength: maxLength(255),
  },
};

const v$ = useVuelidate(rules, customAttribute);

const errorMessages = computed(() => {
  if (v$.value.$error) {
    return Object.values(v$.value.$errors).map((error) => error.$message);
  }
  return [];
});

const customAttrLabel = (attr) => {
  return attr.required ? `${attr.name} (required)` : attr.name;
};
</script>
