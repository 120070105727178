<template>
  <div class="tw-w-full tw-rounded tw-border tw-bg-white tw-p-4 tw-shadow-portal sm:tw-p-6">
    <!-- Desktop  -->
    <div class="tw-mb-5 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
      <div class="tw-text-xl tw-font-bold">Ticket Details</div>
      <SpiceworksButton
        button-type="primary"
        :text="statusLabel"
        type="submit"
        @click="changeTicketStatus"
      />
    </div>
    <table class="tw-flex tw-flex-col">
      <tr>
        <td class="ticket-property-title">Assignee</td>
      </tr>
      <tr class="tw-mb-3">
        <td class="tw-break-normal tw-capitalize">{{ ticket.assignee }}</td>
      </tr>
      <tr>
        <td class="ticket-property-title">Category</td>
      </tr>
      <tr class="tw-mb-3">
        <td class="tw-break-normal tw-capitalize">
          {{ ticket.details.ticketCategory.name || 'Unspecified' }}
        </td>
      </tr>
      <tr>
        <td class="ticket-property-title">Status</td>
      </tr>
      <tr class="tw-mb-3">
        <td class="tw-break-normal tw-capitalize">{{ ticket.status }}</td>
      </tr>
      <tr>
        <td class="ticket-property-title">Created</td>
      </tr>
      <tr class="tw-mb-3">
        <td class="tw-break-normal">{{ ticket.details.customDates.createdAt }}</td>
      </tr>
      <template
        v-for="customValue in ticket.customValues"
        :key="customValue?.id"
      >
        <tr>
          <td class="ticket-property-title">{{ customValue.name }}</td>
        </tr>
        <tr class="tw-mb-3">
          <td class="tw-break-normal tw-capitalize">{{ customValue.value || 'Unspecified' }}</td>
        </tr>
      </template>
    </table>
    <!-- Mobile/Tablet  -->
    <InfoModal
      v-model:is-showing-dialog="isShowingDialog"
      title="Ticket Details"
      @close-dialog="closeDialog"
    >
      <template #content>
        <SpiceworksButton
          button-type="primary"
          :text="statusLabel"
          class="mb-4 mt-3"
          type="submit"
          @click="changeTicketStatus"
        />
        <table class="tw-flex tw-flex-col">
          <tr>
            <td class="ticket-property-title">Assignee</td>
          </tr>
          <tr class="tw-mb-3">
            <td class="tw-break-normal tw-capitalize">{{ ticket.assignee }}</td>
          </tr>
          <tr>
            <td class="ticket-property-title">Category</td>
          </tr>
          <tr class="tw-mb-3">
            <td class="tw-break-normal tw-capitalize">
              {{ ticket.details.ticketCategory.name || 'Unspecified' }}
            </td>
          </tr>
          <tr>
            <td class="ticket-property-title">Status</td>
          </tr>
          <tr class="tw-mb-3">
            <td class="tw-break-normal tw-capitalize">{{ ticket.status }}</td>
          </tr>
          <tr>
            <td class="ticket-property-title">Created</td>
          </tr>
          <tr class="tw-mb-3">
            <td class="tw-break-normal">{{ ticket.details.customDates.createdAt }}</td>
          </tr>
          <template
            v-for="customValue in ticket.customValues"
            :key="customValue?.id"
          >
            <tr>
              <td class="ticket-property-title">{{ customValue.name }}</td>
            </tr>
            <tr class="tw-mb-3">
              <td class="tw-break-normal tw-capitalize">
                {{ customValue.value || 'Unspecified' }}
              </td>
            </tr>
          </template>
        </table>
      </template>
    </InfoModal>
  </div>
</template>

<script setup>
import { computed, watchEffect, ref } from 'vue';
import { useStore } from 'vuex';
import InfoModal from '@/src/common/components/InfoModal.vue';
import { useMobile } from '@/src/common/composables/useMobile';
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
  onChangeTicketStatus: {
    type: Function,
    required: true,
  },
  showDetailsModal: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const { isMobile } = useMobile();

const statusLabel = computed(() => {
  return `${props.ticket.status == 'closed' ? 'Reopen' : 'Close'} ticket`;
});

const isShowingDialog = ref(false);
const emit = defineEmits(['update:show-details-modal']);

watchEffect(() => {
  isShowingDialog.value = props.showDetailsModal;
});

const closeDialog = () => {
  isShowingDialog.value = false;
  emit('update:show-details-modal', false);
};

const changeTicketStatus = async () => {
  try {
    // Don't show the snackbar for mobile
    await props.onChangeTicketStatus(!isMobile.value);
  } catch (err) {
    const snackbarProps = {
      display: true,
      message: 'An error occurred while updating the ticket. Please try again.',
      type: 'error',
    };
    await store.dispatch('showSnackbar', snackbarProps);
  }
};
</script>

<style scoped>
.ticket-property-title {
  @apply tw-break-normal tw-pr-5 tw-text-left tw-font-bold tw-capitalize;
}
</style>
