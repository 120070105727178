<template>
  <section class="tw-w-full">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center lg:tw-flex-row lg:tw-items-start"
    >
      <div
        v-if="announcement"
        class="tw-mx-4 tw-mb-4 tw-w-85 tw-flex-none sm:tw-w-full sm:tw-max-w-176 sm:tw-flex-auto lg:tw-w-85 lg:tw-flex-none"
      >
        <PortalAnnouncement :announcement="announcement" />
      </div>
      <div
        class="tw-mx-4 tw-mb-4 tw-w-85 tw-flex-none sm:tw-w-full sm:tw-max-w-176 sm:tw-flex-auto"
      >
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup>
import PortalAnnouncement from '@/src/portal/components/PortalAnnouncement.vue';
import { ref, defineProps } from 'vue';

const props = defineProps({
  announcement: {
    type: String,
    default: '',
  },
});

const announcement = ref(props.announcement);
</script>
