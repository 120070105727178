<template>
  <div class="tw-w-full tw-rounded tw-border tw-bg-white tw-py-4 tw-shadow-portal sm:tw-p-6">
    <LoadingSpinner v-if="isLoading" />
    <div v-else>
      <v-data-table
        :hide-default-header="isMobile"
        :headers="headers"
        :items="tickets"
        :items-per-page="15"
        :search="search"
        class="tickets-data-table"
      >
        <template #top>
          <div
            class="tw-my-1 tw-flex tw-justify-between tw-border-b tw-border-solid tw-px-4 sm:tw-border-0 sm:tw-px-0 lg:tw-flex-row"
          >
            <v-select
              v-if="!isMobile || (!search && searchClosed)"
              v-model="filter"
              :items="filters"
              class="tw-w-32 tw-justify-center tw-text-xl tw-font-bold tw-text-earl-600"
              @update:model-value="filterTickets"
            ></v-select>
            <!-- Mobile Version -->
            <v-text-field
              v-if="isMobile"
              v-model="search"
              placeholder="Search..."
              class="search tw-w-full tw-flex-initial tw-border-earl-200 tw-pb-2 tw-text-sm"
              :class="{ closed: searchClosed && !search }"
              clearable
              hide-details
              @focus="searchClosed = false"
              @blur="searchClosed = true"
            >
              <template #prepend-inner>
                <v-icon :color="searchClosed ? '#434648' : '#2099BD'">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <!-- Tablet & Desktop version -->
            <v-text-field
              v-else
              v-model="search"
              placeholder="Search..."
              class="tw-w-3/6 tw-flex-initial tw-border-earl-200 tw-px-4 tw-text-sm"
              prepend-inner-icon="mdi-magnify"
              clearable
            ></v-text-field>
          </div>
        </template>
        <template #item="{ item, index }">
          <!-- Mobile Version -->
          <tr
            v-if="isMobile"
            class="tw-cursor-pointer tw-border-b tw-border-solid tw-border-earl-200"
            @click="selectTicket(item)"
          >
            <td
              class="tw-w-full tw-text-sm tw-text-earl-1000"
              style="padding: 0.75rem 1rem"
            >
              <div class="tw-flex tw-justify-between tw-pb-1 tw-text-xs">
                <div class="tw-truncate">{{ item.assignee }}</div>
                <div class="tw-flex tw-truncate">
                  <span>{{ item.updatedAt }}</span>
                </div>
              </div>
              <div class="tw-my-1 tw-line-clamp-1 tw-font-bold">{{ item.summary }}</div>
              <div class="tw-line-clamp-2">{{ item.description }}</div>
            </td>
          </tr>
          <!-- Tablet & Desktop version -->
          <tr
            v-else
            :class="rowStyles(index)"
            @click="selectTicket(item)"
          >
            <td class="ticket-number tw-w-12">{{ item.id }}</td>
            <td class="tw-font-bold">{{ truncate(item.summary, 52) }}</td>
            <td>{{ truncate(item.description, 52) }}</td>
            <td class="tw-w-24">{{ item.assignee }}</td>
            <td class="tw-w-24">{{ item.updatedAt }}</td>
          </tr>
        </template>
        <template #no-data>
          <span
            v-if="filter === 'open'"
            class="tw-my-3 tw-block tw-justify-center tw-text-earl-600"
            >You have no open tickets at the moment. <br />
            Press submit a ticket if you need to request something from IT or Maintenance.
          </span>
          <span
            v-else
            class="tw-text-earl-600"
            >No closed tickets found</span
          >
        </template>
      </v-data-table>
    </div>

    <!-- Mobile create a new ticket button -->
    <v-btn
      v-if="isMobile"
      to="/ticket"
      class="tw-mb-10 tw-mr-10"
      icon="mdi-plus"
      position="fixed"
      location="bottom right"
      variant="elevated"
      color="primary"
      height="48"
      width="48"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getTickets } from '@/src/portal/services/portal-service.js';
import LoadingSpinner from '@/src/portal/components/LoadingSpinner.vue';
import { useMobile } from '@/src/common/composables/useMobile';

const router = useRouter();

const search = ref(null);
const tickets = ref([]);
const filter = ref('open');
const filters = ref([
  { value: 'open', title: 'Open Tickets' },
  { value: 'closed', title: 'Closed Tickets' },
]);
const searchClosed = ref(true);
const isLoading = ref(false);

const { isMobile } = useMobile();

const headers = [
  { title: 'ID', key: 'id', class: 'fixed-padding header' },
  { title: 'Summary', key: 'summary' },
  { title: 'Description', key: 'description' },
  { title: 'Assignee', key: 'assignee', class: 'fixed-padding header' },
  { title: 'Updated', key: 'updatedAt' },
];

onMounted(filterTickets);

async function filterTickets() {
  isLoading.value = true;
  tickets.value = await getTickets(filter.value);
  isLoading.value = false;
}

function rowStyles(index) {
  let styles = 'fixed-padding tw-cursor-pointer';
  styles += index % 2 === 0 ? ' tw-b-0' : ' tw-bg-earl-50';
  return styles;
}

function truncate(str, num) {
  if (str.length <= num) return str;
  else return str.slice(0, num) + '...';
}

function selectTicket(ticket) {
  router.push({ path: `ticket/${ticket.id}` });
}
</script>

<style scoped lang="scss">
// Hide sort dropdown with CSS until vuetify bug https://github.com/vuetifyjs/vuetify/issues/19730 is fixed
:deep(.v-data-table-headers--mobile) {
  display: none;
}

.search {
  max-width: 100%;
  transition: max-width 0.4s;
  &.closed {
    max-width: 1.75rem;
    :deep(.v-field__outline) {
      display: none;
    }
  }
}
</style>
