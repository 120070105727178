<template>
  <v-menu
    :model-value="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    @update:model-value="dateMenu = $event"
  >
    <template #activator="{ props: activatorProps }">
      <v-text-field
        :model-value="customAttribute.value"
        v-bind="activatorProps"
        :label="customAttrLabel(modelValue)"
        :error-messages="errorMessages"
        prepend-inner-icon="mdi-calendar"
        readonly
        clearable
        @click:clear="customAttributeValue = null"
      ></v-text-field>
    </template>
    <v-date-picker
      :model-value="customAttributeValue"
      @update:model-value="handleDateChange"
    ></v-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { formatDateToUserDisplayFormat } from '@/src/common/helpers/date-helpers.js';

const props = defineProps({
  modelValue: {
    type: undefined,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const customAttribute = ref({ ...props.modelValue });
const dateMenu = ref(false);

const customAttributeValue = computed({
  get: () => (customAttribute.value.value ? new Date(customAttribute.value.value) : null),
  set: (val) => {
    customAttribute.value = { ...props.modelValue, value: val };
    emit('update:modelValue', customAttribute.value);
  },
});

const rules = {
  value: {
    required: requiredIf((_value, model) => model.required),
  },
};

const v$ = useVuelidate(rules, customAttribute);

const errorMessages = computed(() => {
  if (v$.value.$error) {
    return Object.values(v$.value.$errors).map((error) => error.$message);
  }
  return [];
});

const customAttrLabel = (attr) => {
  return attr.required ? `${attr.name} (required)` : attr.name;
};

const handleDateChange = (val) => {
  customAttributeValue.value = formatDateToUserDisplayFormat(val, 'yyyy-MM-dd');
  dateMenu.value = false;
};
</script>
