import {
  getPortalSettings,
  getCustomAttributes,
  getEndUsers,
} from '@/src/portal/services/portal-service.js';
import { createState, createGetters, createMutations, createActions } from '@/src/store.js';

export default {
  plugins: [],

  state: {
    gRecaptchaSiteKey: '',
    portalSettings: null,
    portalCustomAttributes: [],
    currentUser: null,
    ...createState(),
  },

  mutations: {
    SET_G_RECAPTCHA_SITE_KEY(state, payload) {
      state.gRecaptchaSiteKey = payload;
    },

    SET_PORTAL_SETTINGS(state, payload) {
      state.portalSettings = payload;
    },

    SET_PORTAL_CUSTOM_ATTRIBUTES(state, payload) {
      state.portalCustomAttributes = payload;
    },

    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },

    ...createMutations(),
  },

  actions: {
    async setPortalData(context, portalSettingsId) {
      const { portalSetting, ticketCategories } = await getPortalSettings(portalSettingsId);

      const { customAttributes } = await getCustomAttributes();

      context.commit('SET_PORTAL_SETTINGS', portalSetting || {});
      context.commit('SET_TICKET_CATEGORIES', ticketCategories || []);
      context.commit('SET_PORTAL_CUSTOM_ATTRIBUTES', customAttributes || []);

      if (portalSetting.currentUser) {
        const { endUser } = await getEndUsers(portalSetting.currentUser.id);
        context.commit('SET_CURRENT_USER', endUser);
      }
    },

    setGRecaptchaSiteKey(context, gRecaptchaSiteKey) {
      context.commit('SET_G_RECAPTCHA_SITE_KEY', gRecaptchaSiteKey);
    },

    ...createActions(),
  },

  modules: {},

  getters: {
    ...createGetters(),
  },
};
